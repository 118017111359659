<script setup lang="ts">
import type { UIProductGalleryProps } from './UIProductGallery.props'
import { Pagination, Mousewheel, A11y } from 'swiper/modules'

const props = withDefaults(defineProps<UIProductGalleryProps>(), {
  currentIndex: 0,
})
const slideChangeCount = ref(0)

const isZoomEnabled = ref(false)
const currentSlideIndex = ref(0)
const handleSlideChange = (swiper: { activeIndex: number }) => {
  currentSlideIndex.value = swiper.activeIndex
  slideChangeCount.value++
}

onMounted(() => {
  document.addEventListener('keydown', handleKeyDown)
})

// this is the computed property that adjusts the slide index based on
// the responsiveImage3DIndices prop in case of 3D images in the gallery
const adjustedSlideIndex = computed(() => {
  if (
    !props.responsiveImage3DIndices ||
    props.responsiveImage3DIndices.length === 0
  ) {
    currentSlideIndex.value = props.currentIndex
    return props.currentIndex
  }

  const currentIndex = props.currentIndex
  let adjustedIndex = currentIndex
  props?.responsiveImage3DIndices?.forEach(index => {
    if (currentIndex > index) {
      adjustedIndex--
    }
  })
  currentSlideIndex.value = adjustedIndex
  return adjustedIndex
})

const handleKeyDown = (event: KeyboardEvent) => {
  const swiper = (document.querySelector('.swiperInternal') as any)?.swiper
  if (!swiper) return
  if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
    swiper.slidePrev()
  } else if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
    swiper.slideNext()
  }
}

const toggleZoom = (zoomEnabled: boolean) => {
  isZoomEnabled.value = !zoomEnabled
  const swiper = (document.querySelector('.swiperInternal') as any)?.swiper
  swiper.allowTouchMove = !isZoomEnabled.value

  // if you want to lock swiper scroll on zoom
  // swiper.allowSlideNext = !isZoomEnabled.value
  // swiper.allowSlidePrev = !isZoomEnabled.value
}

onUnmounted(() => {
  document.removeEventListener('keydown', handleKeyDown)
})
</script>
<template>
  <OrganismsModal
    :id="id"
    :description="$ts('cartPage.imageGallery.title')"
    :uses-highest-z-index="true"
    force-global-theme
  >
    <template #body>
      <div class="flex h-full w-full items-center justify-center">
        <Swiper
          :direction="'vertical'"
          :modules="[Mousewheel, Pagination, A11y]"
          :mousewheel="true"
          :pagination="{
            el: '#swiper-pagination',
            type: 'custom',
          }"
          :speed="1000"
          class="swiperInternal max-h-screen shadow"
          :initial-slide="adjustedSlideIndex"
          grab-cursor
          @slide-change="handleSlideChange"
        >
          <SwiperSlide
            v-for="(media, index) in gallery"
            :key="`product-gallery-slide-${media.data}`"
            class="py-10 sm:py-0"
          >
            <UIZoomableImage
              v-if="media.type === 'TResponsiveImage'"
              :src="media.data"
              :alt="`${title} - Slide ${index + 1}`"
              :reset-zoom="slideChangeCount"
              full-size
              @toggle-zoom="toggleZoom"
            />
            <UIFullScreenVideo
              v-else-if="media.type === 'TResponsiveVideo'"
              :src="media.data.url"
            />
          </SwiperSlide>
          <div id="swiper-pagination">
            <div
              class="absolute left-4 top-1/2 z-[9999] flex -translate-y-1/2 flex-col items-center md:left-7 lg:left-[51px] xl:left-[67.5px]"
            >
              <div class="mb-5 text-[10px] font-normal leading-[15px]">
                {{ currentSlideIndex + 1 }}
              </div>
              <ul>
                <li
                  v-for="(slide, index) in gallery"
                  :key="`swiper-pagination-item-${slide.type}-${index}`"
                  class="h-4 w-0.5"
                  :class="[
                    index === currentSlideIndex
                      ? 'bg-primitives-black'
                      : 'bg-primitives-grey-150',
                  ]"
                ></li>
              </ul>
              <div class="mt-5 text-[10px] font-normal leading-[15px]">
                {{ gallery.length }}
              </div>
            </div>
          </div>
        </Swiper>
      </div>
    </template>
  </OrganismsModal>
</template>
